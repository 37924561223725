body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
a {
  color: white;
}
.menu__header {
  white-space: nowrap;
  margin: 24px;
}

.menu__header svg {
  font-size: 30px;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  color: #e3000f;
}

/* info ribbon bottom right, see-through on hover */
.ribbon {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: fixed;
  bottom: 0px;
  right: 0px;

  transition: opacity 1s;
  opacity: 1;
}
.ribbon:hover {
  opacity: 0.01;
}
.ribbon .ribbon-inner {
  position: absolute;
  display: block;
  width: 300px;
  padding: 15px 0;
  background-color: #222222;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  left: -30px;
  bottom: 50px;
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
}
.ribbon span {
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

